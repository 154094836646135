<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Faliyet   {{ eventLocal.id ? 'Güncelle': 'Ekle' }}
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="deleteData(hide)"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Başlık"
                label-for="event-title"
              >
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Faaliyet Başlığı"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Calendar -->
            <validation-provider
              #default="validationContext"
              name="Faaliyet Türü"
              rules="required"
            >

              <b-form-group
                label="Faaliyet Türü"
                label-for="calendar"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.activityType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="calendarOptions"
                  label="name"
                  :reduce="calendar => calendar.id"
                  input-id="calendar"
                >

                  <template #option="{ color, name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>

                  <template #selected-option="{ color, name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules="required"
            >

              <b-form-group
                label="Başlangıç Zamanı"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="$staticParams.flatPickrConfig"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules="required"
            >

              <b-form-group
                label="Bitiş Zamanı"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="$staticParams.flatPickrConfig"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- All Day -->
            <b-form-group>
              <b-form-checkbox
                v-model="eventLocal.allDay"
                name="check-button"
                switch
                inline
              >
                {{ $t('Tüm Gün') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- Event URL -->
            <validation-provider
              v-if="true==false"
              #default="validationContext"
              name="Event URL"
              rules="url"
            >

              <b-form-group
                label="Faaliyet Linki (Opsiyonel)"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.url"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="Faaliyetinizi Anlatan Link Varsa Giriniz"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Guests -->
            <b-form-group
              label="Müşteri (Opsiyonel)"
              label-for="add-guests"
            >
              <vue-autosuggest

                :suggestions="customerSuggest.filteredCustomers"
                :input-props="customerSuggest.inputProps"
                :value="eventLocal.extendedProps.customerName"
                @selected="customerSelected"
                @input="customerOnInputChanged"
              >
                <template slot-scope="{suggestion}">
                  <div class="d-flex align-items-center">

                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.name }}
                      </b-card-text>
                      <small class="text-muted">
                        {{ suggestion.item.id }}
                      </small>
                    </div>
                  </div>
                </template>
              </vue-autosuggest>
            </b-form-group>

            <!-- Location -->
            <b-form-group
              label="Lokasyon"
              label-for="event-location"
            >
              <b-form-input
                id="event-location"
                v-model="eventLocal.extendedProps.location"
                trim
                placeholder="Bu faaliyet nerede ?"
              />
            </b-form-group>

            <!-- Textarea -->
            <b-form-group
              label="Açıklama"
              label-for="event-description"
            >
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.extendedProps.description"
                placeholder="Faaliyet açıklaması giriniz."
              />
            </b-form-group>
            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <span><strong>Bilgilendirme!</strong>Eğer faaliyet henüz yapılmamışsa ve ileriki tarihte yapılması planlanıyorsa "Yapılmamış Faaliyet" alanını seçiniz.</span>
              </div>
            </b-alert>
            <b-form-group>
              <b-form-radio
                v-model="eventLocal.extendedProps.isCompleted"
                name="some-radios"
                :value="true"
              >

                Yapılmış Faaliyet
              </b-form-radio>
            </b-form-group>
            <b-form-group>
              <b-form-radio
                v-model="eventLocal.extendedProps.isCompleted"
                name="some-radios"
                :value="false"
              >
                Yapılacak Faaliyet
              </b-form-radio>
            </b-form-group>
            <hr>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? 'Güncelle' : 'Oluştur ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Sıfırla
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton, BFormInvalidFeedback, BFormRadio, BAlert, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { VueAutosuggest } from 'vue-autosuggest'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BAlert,
    BSidebar,
    VueAutosuggest,
    BFormRadio,
    BForm,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,

    }
  },
  methods: {
    deleteData(hide) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t(`${this.event.title} isimli  faaliyeti silmek üzeresiniz. Silindiğinde bu işlem geri alınamaz.`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('remove-event'); hide()
        }
      })
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      customerSelected,
      customerOnInputChanged,
      customerSuggest,
      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      customerSelected,
      customerOnInputChanged,
      customerSuggest,
      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
